.project{

    &__outer-wrapper{
        width: 100%;
        position: relative;
    }

    &__header-outer-wrapper{
        position: absolute;
        width: calc(50% - 100px);
        padding: calc(50vh - 50px) 0 10px 0;
        right: 0;
        top: 0;
        height: calc(100% - 50vh - 50px);
        z-index: 50;
        pointer-events: none;
    }

    &__header-inner-wrapper{
        position: sticky;
        top: 0;
        padding-top: 10px;
        margin-left: 10px;
        display: grid;
        width: calc(100% - 50px);
        grid-template-columns: 30% 30% 30% 10%;
        column-gap: 10px;
    }

    &__header-item{
        display: inline;
        padding: 10px !important;
    }

    &__spacer{
        width: 100%;
        height: 50vh;
    }

    &__media-wrapper{
        height: calc(100vh - 300px);
        width: calc(100% - 300px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 150px ;
        &.vimeo{
            min-height: calc(100vh - 300px);
            width: 50vw;
            padding: 150px 25vw;
            height: auto;
        }
    }

    &__media-image {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
}

@include mobile{

    .project{

        &__header-outer-wrapper{
            width: 100%;
            padding: calc(50vh - 100px) 0 0 0;
            right: auto;
            left: 0;
            top: 0;
        }
    
        &__header-inner-wrapper{
            position: sticky;
            top: 38px;
            display: grid;
            width: calc(100% - 30px);
            grid-template-columns: 50% 50%;
            column-gap: 10px;
            row-gap: 10px;
        }

        &__media-wrapper{
            // height: calc(100vh - 90px);
            height: auto;
            width: calc(100% - 40px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 20px 90px 20px;
            &.vimeo{
                // min-height: calc(100vh - 300px);
                width: calc(100% - 40px);
                padding: 0 20px 90px 20px;
                // height: auto;
            }
        }
    }
}