.navigation{

    &__logo{
        margin: 10px 0;
        position: fixed;
        top: 0;
        z-index: 100;
        left: 50%;
        transform: translateX(-50%);
        text-wrap: nowrap;
        width: 140px;
        text-align: center;
    }

    &__back{
        margin: 10px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.4s ease-in-out;
        &.active{
            opacity: 1;
            pointer-events: all;
        }
    }

    &__archive{
        margin: 10px 0;
        position: fixed;
        bottom: 0;
        z-index: 100;
        left: 50%;
        transform: translateX(-50%);
    }
}