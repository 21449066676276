.credits{

    &__button{
        margin: 10px;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 100;
    }

    &__wrapper{
        width: 40%;
        height: var(--app-height);
        padding: 0 30%;
        position: fixed;
        z-index: 99;
        background-color: #FFFFFF99;
        backdrop-filter: blur(5px);
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        pointer-events: none;
        &.active{
            pointer-events: all;
            opacity: 1;
        }
    }

}

@include mobile{
    .credits{
        &__wrapper{
            width: calc(100% - 40px);
            padding: 0 20px;
        }
    }
}