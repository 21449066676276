*::selection {
  background: #FFF;
  color: #000;
}
*::-moz-selection {
  background: #FFF;
  color: #000;
}

*::-webkit-scrollbar {
  width: 0px;
  display: none;
}
*::-webkit-scrollbar-track {
  background-color: #FFF;
  display: none;
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
  border: none;
}


html,
body {
  width: 100vw;
  height: var(--app-height);
}

.button-background{
  padding: 10px 20px;
  background-color: #D9D9D950;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  padding: 10px 20px 7px 20px;
}

.credits__wrapper a{text-decoration: underline;}