@charset "UTF-8";

// ==========================================================================
// Plyr styles
// https://github.com/sampotts/plyr
// TODO: Review use of BEM classnames
// ==========================================================================

@import "lib/css-vars";

$css-vars-use-native: true;

@import "settings/breakpoints";
@import "settings/colors";
@import "settings/cosmetics";
@import "settings/type";
@import "settings/badges";
@import "settings/captions";
@import "settings/controls";
@import "settings/helpers";
@import "settings/menus";
@import "settings/progress";
@import "settings/sliders";
@import "settings/tooltips";
@import "lib/animation";
@import "lib/functions";
@import "lib/mixins";
@import "base";
@import "components/badges";
@import "components/captions";
@import "components/control";
@import "components/controls";
@import "components/menus";
@import "components/sliders";
@import "components/poster";
@import "components/times";
@import "components/tooltips";
@import "components/progress";
@import "components/volume";
@import "types/audio";
@import "types/video";
@import "states/fullscreen";
@import "plugins/ads";
@import "plugins/preview-thumbnails/index";
@import "utils/animation";
@import "utils/hidden";

:root {
    // --plyr-color-main: rgb(3, 46, 0);
    --plyr-color-main: rgba(255, 255, 255, 0.3);
    --plyr-badge-border-radius: 0;
    --plyr-control-radius: 0;
}

// .plyr_volume{
//     width: auto;
// }
// .plyr__control {
//     border-left: 1px solid #FFF;
//     border-right: 1px solid #FFF;
// }