.loading{

    &__wrapper{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__dot{
        opacity: 0;
        &.active{
            opacity: 1;
        }
    }
}