.archive{

    &__back{
        margin: 10px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 50;
    }

    &__canvas-wrapper{
        width: 100%;
        height: var(--app-height);
        position: fixed;
        top: 0;
        left: 0;
    }

    &__canvas-inner{
        width: 280vw;
        height: 280vh;
        position: absolute;
        top: -100%;
        left: -100%;
        padding: 10vh 10vw;
        background-color: #EEE;
    }

    &__canvas-inner-inner{
        background-color: #FFF;
        position: relative;
        clear: both;
        -webkit-box-shadow: 0px 0px 50px 30px rgba(255, 255, 255, 1);
        -moz-box-shadow: 0px 0px 50px 30px rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 50px 30px rgba(255, 255, 255, 1);
        padding: 250px 0;
        height: calc(280vh - 500px);
        text-align: center;
    }

    &__image-wrapper{
        width: 200px;
        height: auto;
        position: absolute;
        cursor: pointer;
    }

    &__image{
        width: 100%;
        height: auto;
    }

    &__focus-wrapper{
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 20px;
        // padding: 300px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5;
        backdrop-filter: blur(5px);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.4s ease-in-out;
        &.active{
            opacity: 1;
            pointer-events: all;
        }
    }

    &__focus-image{
        display: block;
        // width: calc(100% - 20px);
        // height: calc(100% - 20px);
        height: calc(100vh - 300px);
        width: calc(100% - 300px);
        // margin: 10px;
        margin: 150px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__focus-close{
        margin: 10px;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 50;
    }

    &__client-name{
        position: fixed;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
    }
    
}

@include mobile {

    .archive{
    
        &__canvas-wrapper{
            width: 100%;
            height: var(--app-height);
            position: fixed;
            top: 0;
            left: 0;
            overflow: scroll;
            background-color: #FFF;
        }
    
        &__canvas-inner{
            width: 400vw;
            height: 200vh;
            position: static;
            top: auto;
            left: auto;
            padding: 10vh 0 0 10vw;
        }
    
        &__canvas-inner-inner{
            background-color: #FFF;
            position: relative;
            // height: calc(280vh - 500px);
            height: 100%;
            width: 100%;
            text-align: center;
        }

        &__focus-image{
            display: block;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            margin: 10px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        &__image-wrapper{
            width: 100px;
        }
    }
}