@font-face {
  font-family: 'HBC';
  src: url('../assets/fonts/HBC.eot');
  src: url('../assets/fonts/HBC.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/HBC.woff2') format('woff2'),
      url('../assets/fonts/HBC.woff') format('woff'),
      url('../assets/fonts/HBC.ttf') format('truetype'),
      url('../assets/fonts/HBC.svg#HBC') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
html,
body {
  font-family: 'HBC', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
  @include hover {
    text-decoration: none;
  }
}
p{
  margin-bottom: 10px;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}


@include mobile {

}
