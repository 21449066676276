.home {
  &__outer-wrapper {
    width: 100%;
    height: calc(var(--app-height) - 20vh);
    padding: 10vh 0;
    overflow-x: auto;
    position: fixed;
    top: 0;
  }

  &__inner-wrapper {
    height: 100%;
    margin: 0 10vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__spacer {
    height: 100%;
    width: 1px;
    flex-shrink: 0;
  }

  &__background-image-wrapper {
    width: calc(100% + 100px);
    height: calc(var(--app-height) + 100px);
    position: fixed;
    top: -50px;
    left: -50px;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
    &.blurred {
      filter: brightness(10) blur(5px);
    }
  }

  &__background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    pointer-events: none;
  }

  &__background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
  }
}

@include mobile {
  .home {
    &__outer-wrapper {
      width: 100%;
      height: calc(var(--app-height) - 20vh);
      padding: 10vh 0;
      overflow-y: auto;
    }

    &__inner-wrapper {
      height: auto;
      margin: 0 20px;
      width: calc(100% - 40px);
      display: block;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    &__spacer {
      display: none;
    }
  }
}
