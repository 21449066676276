.phone {
  &__outer-wrapper {
    height: 80vh;
    width: calc((80vh * 9) / 16);
    flex-shrink: 0;
    margin-right: 10vh;
    border-left: 1px solid #000;
    border-top: 1px solid #000;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    transition: all 0.4s ease-in-out;
    // background-color: aqua;
    opacity: 1;
    pointer-events: all;
    // border-radius: 30px;
    isolation: isolate;
    &.fixed{
      position: fixed;
      top: 10vh;
      left: 50%;
      transform: translateX(-50%);
    }
    &.disabled{
      opacity: 0;
      pointer-events: none;
    }
  }

  // &__media-wrapper{
    // overflow: hidden;
    // border-radius: 30px;
    // isolation: isolate;
  // }

  &__media,
  &__video {
    height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 30px;
    isolation: isolate;
  }

  // &__video{
  //   z-index: ;
  // }

}

@include mobile{

  .phone {
    &__outer-wrapper {
      display: block;
      width: calc(100vw - 40px);
      height: calc(((100vw - 40px) * 16) / 9);
      margin-right: 0;
      margin-bottom: 30px;
      &.fixed{
        position: fixed;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
      }
    }
  }
}
